import { FC } from 'react';
import styled from 'styled-components';

import { HowWhiskeyvestWorksCard, HowWhiskeyvestWorksProps } from './HowWhiskeyvestWorksCard';

interface HowWhiskeyvestWorksListProps {
    cards: HowWhiskeyvestWorksProps[];
}
export const HowWhiskeyvestWorksList: FC<HowWhiskeyvestWorksListProps> = ({ cards }) => {
    return (
        <HiwContainer>
            <ul className={'grid grid-cols-1 gap-y-12 lg:gap-y-0 lg:grid-cols-4 lg:gap-x-16 mx-[15px] md:mx-0'}>
                {cards.map((cardProps) => {
                    return <HowWhiskeyvestWorksCard {...cardProps} key={cardProps.id} />;
                })}
            </ul>
        </HiwContainer>
    );
};

export const HiwContainer = styled.div`
    position: relative;
    @media screen and (min-width: 768px) {
        padding: 40px;
    }
    @media screen and (min-width: 1024px) {
        padding: 80px 80px 0;
    }
`;
