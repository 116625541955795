import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { PosthogHelper } from '~/helpers/PosthogHelper';
import { AnalyticsEventNames } from '~/tracking';

export const WhiskeyReserveNav = () => {
    const [isActive, setIsActive] = useState('Buy');

    const handleClick = (id: string, itemName: string) => {
        PosthogHelper.captureEvent(AnalyticsEventNames.AnchorItem, { item: itemName });
        setIsActive(id);
    };

    useEffect(() => {
        const handleScroll = () => {
            // Iterate through your sections and find the one in the viewport
            const sections = ['Buy', 'HowWhiskeyvestWorks', 'WhatToExpect', 'ValueProp', 'WhiskeyEducationSupport'];

            for (const sectionId of sections) {
                const sectionElement = document.querySelector(`#${sectionId}`);

                if (sectionElement) {
                    const rect = sectionElement.getBoundingClientRect();
                    if (rect.top <= 100 && rect.bottom >= 0) {
                        setIsActive(sectionId);
                        break;
                    }
                }
            }
        };

        // Add scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <div
                className={
                    'max-w-[1170px] z-10 overflow-x-scroll md:overflow-x-hidden overflow-y-hidden w-full flex md:justify-center items-center md:mt-[30px] fixed md:sticky top-[75px] bg-turtleGreen-800 pb-[10px] px-[20px] pt-[10px] md:pt-[0]'
                }
            >
                <ReserveNav className={'flex justify-between gap-8 mb-[-20px] whitespace-nowrap'}>
                    <a
                        href={'#Buy'}
                        onClick={() => handleClick('Buy', 'buy')}
                        className={`${isActive === 'Buy' && 'active'}`}
                    >
                        Invest
                    </a>
                    <a
                        href={'#WhatToExpect'}
                        onClick={() => handleClick('WhatToExpect', 'exit')}
                        className={`${isActive === 'WhatToExpect' && 'active'}`}
                    >
                        What to Expect
                    </a>
                    <a
                        href={'#HowWhiskeyvestWorks'}
                        onClick={() => handleClick('HowWhiskeyvestWorks', 'how it works')}
                        className={`${isActive === 'HowWhiskeyvestWorks' && 'active'}`}
                    >
                        How it Works
                    </a>
                    <a
                        href={'#ValueProp'}
                        onClick={() => handleClick('ValueProp', 'benefits')}
                        className={`${isActive === 'ValueProp' && 'active'}`}
                    >
                        Benefits
                    </a>
                    <a
                        href={'#WhiskeyEducationSupport'}
                        onClick={() => handleClick('WhiskeyEducationSupport', 'faq')}
                        className={`${isActive === 'WhiskeyEducationSupport' && 'active'}`}
                    >
                        FAQ
                    </a>
                </ReserveNav>
            </div>
        </>
    );
};

const ReserveNav = styled.nav`
    height: 60px;
    a {
        display: inline-flex;
        height: 40px;
    }
    .active {
        position: relative;
        z-index: 0;
        &:after {
            background-color: #efddc7;
            bottom: 0px;
            content: ' ';
            display: block;
            height: 1px;
            left: 0;
            position: absolute;
            width: 100%;
        }
    }
`;
