import { FC } from 'react';
import { ImageProps } from 'next/legacy/image';
import styled from 'styled-components';

import { Text } from '~/vinovest-design-system/components';
import Image from 'next/image';

export interface WhatToExpectListProps {
    title?: string;
    imgSrc?: ImageProps['src'];
    holdTime?: string;
    exitPrice?: string;
    returnValue?: string;
    id?: string;
}
export const WhatToExpectListCard: FC<WhatToExpectListProps> = (props) => {
    const { title, imgSrc, holdTime, exitPrice, returnValue } = props;
    return (
        <div
            className={
                'flex flex-col border-[1px] border-[#b5b5b5] border-solid rounded-[8px] md:max-w-[180px] text-left'
            }
        >
            {imgSrc && (
                <div className={'bg-gray-200  rounded-[8px] p-[20px]'}>
                    <Image src={imgSrc} alt={`${title} Barrel Image`} />
                </div>
            )}
            <div className={'p-[10px] text-[14px] relative z-1 h-[170px] sm:h-[200px]'}>
                <Text className={'pb-[10px]'}>{title}</Text>
                <CardInfoList className={'absolute bottom-[20px] left-[10px] md:w-[160px]'}>
                    <li className={'flex border-b-[1px] border-[#b5b5b5] border-solid w-[100%] py-[5px]'}>
                        <Text className={'w-[50%]'}>Hold Time</Text>
                        <Text className={'text-right w-[50%]'}>{holdTime}</Text>
                    </li>
                    <li className={'flex border-b-[1px] border-[#b5b5b5] border-solid w-[100%] py-[5px]'}>
                        <Text className={'w-[50%]'}>Exit Price</Text>
                        <Text className={'text-right w-[50%]'}>{exitPrice}</Text>
                    </li>
                    <li className={'flex  w-[100%] py-[5px]'}>
                        <Text className={'w-[50%]'}>Return</Text>
                        <Text className={'text-green-600 text-right w-[50%]'}>{returnValue}</Text>
                    </li>
                </CardInfoList>
            </div>
        </div>
    );
};

export const CardInfoList = styled.ul`
    width: calc(100% - 20px);
`;
