import { useEffect } from 'react';
import { useRouter } from 'next/router';

import { SessionStorageHelper } from '~/helpers/StorageHelper';

export enum UtmParams {
    Source = 'utm_source',
    Medium = 'utm_medium',
    Campaign = 'utm_campaign',
    Content = 'utm_content',
    Term = 'utm_term'
}

/**
 * Capture UTM parameters.
 * ex. /signup?utm_source=facebook&utm_medium=ocpm\
 * &utm_campaign=FB-Conversion-LongSignal-US\
 * &utm_content=SignupDeposit-AffluentZipCodes-EN-ComparisonLP\
 * &utm_term=Aug22-Diversification-Apple-1-Video
 */
export const useCaptureUtmParams = () => {
    const router = useRouter();

    useEffect(() => {
        const utmValues = Object.values(UtmParams);
        const hasUtmValues = utmValues.some((param) => !!router.query[param]);
        if (hasUtmValues) {
            utmValues.map((utmParam) => {
                if (router.query[utmParam]) {
                    SessionStorageHelper.setItem(utmParam as any, router.query[utmParam]);
                }
            });
        }
    }, [router.query]);
};
