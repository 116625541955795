import { ImageProps } from 'next/legacy/image';

import BottleCask from '~/assets/img/reserve-cask/svg/bottle-cask.svg';
import Historical from '~/assets/img/reserve-cask/svg/historical.svg';
import Insured from '~/assets/img/reserve-cask/svg/insured.svg';
import RecessionProof from '~/assets/img/reserve-cask/svg/recession-proof.svg';
import ScheduleVisit from '~/assets/img/reserve-cask/svg/schedule-visit.svg';

import { ValuePropCardProps } from './ValuePropCard';

const makeValueCard = (
    title: string,
    imgSrc: ImageProps['src'],
    imgWidth: ImageProps['width'],
    imgHeight: ImageProps['height'],
    description: string,
    badgeTitle?: string,
    id?: string
) => {
    return { title, imgSrc, imgHeight, imgWidth, description, badgeTitle, id: id || title };
};

export const valuePropCardConstants: ValuePropCardProps[] = [
    makeValueCard('Insured & Managed', Insured, 144, 144, 'Insured and managed by the whiskey experts'),
    makeValueCard(
        'Recession-Resistant',
        RecessionProof,
        144,
        144,
        'Take the sting out of tough times with a recession-resistant asset'
    ),
    makeValueCard('Visit it', ScheduleVisit, 144, 144, 'Schedule a visit to see your cask in person'),
    makeValueCard('Bottle it', BottleCask, 144, 144, 'Don’t want to sell your cask? Opt to have it bottled'),
    makeValueCard(
        'Historically Great',
        Historical,
        144,
        144,
        'Historically, whiskey returns 12-18% on investment per year'
    )
];
