import Image from 'next/legacy/image';

import TryWineDt from '~/assets/img/reserve-cask/try-wine-dt.png';
import TryWineMo from '~/assets/img/reserve-cask/try-wine-m.png';
import { AnalyticsEventNames } from '~/constants/AnalyticsEventMap';
import { useTracking } from '~/hooks/tracking/useTracking';
import { useWindowSize } from '~/hooks/useWindowSize';
import { managedRoutes } from '~/routes/managed';
import { Button } from '~/vinovest-design-system/components';

const brand = {
    subtext: 'Starting at $1,000',
    description: 'Just Like Whiskey, Great Wine Gets Better with Age.  Expand Your Portfolio Today.',
    btn_label: 'Invest in wine'
};

export const TryWineCard = () => {
    const { captureEvent } = useTracking();

    const handleInvestClick = () => {
        captureEvent(AnalyticsEventNames.SelectWine);
    };

    const { isMobile } = useWindowSize();

    return (
        <div
            className={`flex bg-platinum-800 w-full md:flex-row md:h-[383px] mt-[81px] max-w-[1170px] mx-auto rounded-[8px] p-[40px] relative z-0 min-h-[450px]`}
        >
            <div className={'text-center relative z-20 w-full md:text-left'}>
                <p
                    className={
                        'font-roslindale text-brown-300 text-[24px] md:text-[32px] leading-[41px] text-center max-w-[590px] mx-auto md:mx-0 md:text-left'
                    }
                >
                    {brand.description}
                </p>
                <p className={'text-brown-300 text-[14px] text-center my-4  md:text-left'}>{brand.subtext}</p>
                <div className={'flex flex-col items-center md:items-start w-full'}>
                    <Button
                        onClick={handleInvestClick}
                        href={managedRoutes.overview.href}
                        variant={'custom'}
                        classnames={`border-brown-300 bg-brown-300 border-2 w-[222px] h-[77px] font-vinovestmedium text-[16px] uppercase mt-[35px] mb-[25px] md:mb-0 text-platinum-800 flex items-center justify-center`}
                    >
                        {brand.btn_label}
                    </Button>
                </div>
            </div>
            <div className={'absolute z-1 left-0 md:left-[auto] md:right-0 bottom-0'}>
                <Image
                    src={isMobile ? TryWineMo : TryWineDt}
                    height={isMobile ? '335' : '397'}
                    width={isMobile ? '344' : '718'}
                />
            </div>
        </div>
    );
};
