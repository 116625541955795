import { useMutation, useQueryClient } from 'react-query';

import { mutations } from '~/constants/mutationKeys';
import { queries } from '~/constants/queryKeys';
import { dispatchError } from '~/containers/ErrorContainer/errorEventListener';
import { VinovestNetworkingHelper } from '~/helpers/VinovestNetworkingHelper';
import { useAuthentication } from '~/hooks/useAuthentication';
import { PutUserProfilePayload } from '~/networking';

export const usePutProfile = () => {
    const { userId } = useAuthentication();
    const queryClient = useQueryClient();
    return useMutation(
        [mutations.UpdateUserProfile, userId],
        async (investmentPayload: PutUserProfilePayload) => {
            const { managed } = VinovestNetworkingHelper.getClient();
            const result = await managed.putUserProfile(investmentPayload);

            if (!result.ok) {
                dispatchError('Failed to update profiles');
                return new Error('Failed to update user profile');
            }
            return result;
        },
        {
            retry: true,
            onSuccess: () => {
                queryClient.invalidateQueries([queries.getManagedPortfolio]);
            }
        }
    );
};
