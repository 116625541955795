import { useMemo } from 'react';
import { QueryClient, useQuery } from 'react-query';

import { queries } from '~/constants/queryKeys';
import { dispatchError } from '~/containers/ErrorContainer/errorEventListener';
import { SentryHelper } from '~/helpers/Sentry/SentryHelper';
import {
    VinovestNetworkHelperAuthenticationParams,
    VinovestNetworkingHelper
} from '~/helpers/VinovestNetworkingHelper';
import { useAuthentication } from '~/hooks/useAuthentication';
import { GetUserPortfolioResponse, PortfolioPerformanceRanges } from '~/networking';

import { PortfolioInformation, transformPortfoliosToKeys, useGetUserPortfolios } from '../useGetUserPortfolios';

export const WhiskeyPortfolioQuery = {
    load: async (
        authenticationParams: VinovestNetworkHelperAuthenticationParams,
        selectedRange: PortfolioPerformanceRanges
    ): Promise<(GetUserPortfolioResponse & { code?: number; message?: string }) | null> => {
        const { userId, portfolioId } = authenticationParams;
        const { managed } = VinovestNetworkingHelper.getClient(authenticationParams);
        try {
            const userPortfolio = await managed.getUserPortfolio(selectedRange, portfolioId);
            if (!userPortfolio.data) {
                dispatchError('Error loading portfolio');
                return null;
            }
            return userPortfolio.data;
        } catch (error) {
            SentryHelper.captureException(error, {
                extra: { userId }
            });
            dispatchError(error);
            return null;
        }
    },
    queryKey: (userId: string, selectedRange: PortfolioPerformanceRanges) => [
        queries.getWhiskeyPortfolio,
        userId,
        selectedRange
    ],
    prefetch: async (
        queryClient: QueryClient,
        authenticationParams: VinovestNetworkHelperAuthenticationParams,
        selectedRange: PortfolioPerformanceRanges
    ) => {
        const { userId } = authenticationParams;
        const queryKey = WhiskeyPortfolioQuery.queryKey(userId || '', selectedRange);
        await queryClient.prefetchQuery(queryKey, async () =>
            WhiskeyPortfolioQuery.load(authenticationParams, selectedRange)
        );
    }
};

/** Query for fetching users Whiskey Portfolio */
export const useWhiskeyPortfolioQuery = (selectedRange = PortfolioPerformanceRanges.SixMonths) => {
    const { isAuthenticated, userId } = useAuthentication();
    const { data: userPortfolios, isSuccess } = useGetUserPortfolios();

    const portfolios = useMemo(() => {
        return userPortfolios?.portfolios?.length
            ? transformPortfoliosToKeys(userPortfolios.portfolios)
            : ({ whiskey: { id: '' } } as unknown as PortfolioInformation);
    }, [userId, isAuthenticated, isSuccess, userPortfolios]);

    return useQuery(
        WhiskeyPortfolioQuery.queryKey(userId as string, selectedRange),
        async (): Promise<(GetUserPortfolioResponse & { code?: number; message?: string }) | null> =>
            WhiskeyPortfolioQuery.load({ userId, portfolioId: portfolios.whiskey.id }, selectedRange),
        {
            enabled: Boolean(isAuthenticated && userId && isSuccess && userPortfolios),
            staleTime: 1000 * 60
        }
    );
};
