import { useQuery } from 'react-query';

import { queries } from '~/constants/queryKeys';
import { useAuthentication } from '~/hooks/useAuthentication';
import { UserProfile } from '~/models/UserProfile';

import { getUserProfile } from './fetchers';

/** Query for fetching users profile  */
export const useGetUserProfile = () => {
    const { jwt: token, userId, authStore } = useAuthentication();

    return useQuery<UserProfile>(
        [queries.getUserProfile, userId],
        async () => {
            if (token && userId) {
                const userProfile = await getUserProfile(token, userId);
                if (userProfile) {
                    authStore.updateUserProfile(userProfile);
                    return userProfile;
                }
            }
            return {} as UserProfile;
        },
        {
            enabled: Boolean(token && userId)
        }
    );
};
