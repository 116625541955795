import { FC } from 'react';

import { ValuePropCard, ValuePropCardProps } from './ValuePropCard';

interface ValuePropListProps {
    cards: ValuePropCardProps[];
}
export const ValuePropList: FC<ValuePropListProps> = ({ cards }) => {
    return (
        <ul className={'list-none grid grid-cols-1 gap-y-12 md:gap-y-0 md:gap-x-20'}>
            {cards.map((cardProps) => {
                return <ValuePropCard {...cardProps} key={cardProps.id} />;
            })}
        </ul>
    );
};
