import { Heading } from '~/vinovest-design-system/components';

import { valuePropCardConstants } from './valuePropCardConstants';
import { ValuePropList } from './ValuePropList';

export const ValuePropContainer = () => {
    return (
        <div className={'h-full max-w-[1170px] mx-auto relative pt-[40px]'}>
            <div className={'flex justify-center'}>
                <Heading
                    level={'h2'}
                    classnames={'pb-[60px] xs:!text-[32px] !text-[48px] !leading-[64px] md:!text-[45px]  text-center'}
                >
                    Exclusive Whiskey Benefits
                </Heading>
            </div>

            <ValuePropList cards={valuePropCardConstants} />
        </div>
    );
};
