import { action, makeAutoObservable, runInAction } from 'mobx';
import { LocalStorageHelper } from '~/helpers/StorageHelper';

class BookACallStore {
    public token?: string;

    public isLoading = true;

    public hasBookedCall = false;

    constructor() {
        this.hasBookedCall = LocalStorageHelper.getItem('hasBookedCall') === 'true';

        makeAutoObservable(this, {
            addExternalScript: false,
            setHasBookedCall: action.bound
        });

        this.addExternalScript();
    }

    addExternalScript() {
        if (typeof document === 'undefined') {
            return;
        }

        const head = document.getElementsByTagName('head')[0];
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';
        script.onload = () => {
            runInAction(() => {
                this.isLoading = false;
            });
        };
        head.appendChild(script);
    }

    getTitle(calendlyUrl: 'wine' | 'whiskey' | string) {
        if (calendlyUrl === 'whiskey') {
            return 'Whiskey Investing Introduction';
        }
        return 'Fine Wine Investing Introduction';
    }

    getUrl(
        calendlyUrl: 'wine' | 'whiskey' | string,
        email: string,
        firstName: string,
        lastName: string,
        isAuthenticated: boolean
    ) {
        let url = process.env.NEXT_PUBLIC_HUBSPOT_MEETING_URL as string;
        if (calendlyUrl === 'whiskey') {
            url = process.env.NEXT_PUBLIC_HUBSPOT_WHISKEY_MEETING_URL as string;
        }

        if (isAuthenticated) {
            const t = new URL(url);
            t.searchParams.set('email', email);
            t.searchParams.set('firstName', firstName);
            t.searchParams.set('lastName', lastName);
            t.searchParams.set('embed', 'true');
            url = t.toString();
        }

        return url;
    }

    setHasBookedCall(hasBookedCall: boolean) {
        this.hasBookedCall = hasBookedCall;
        LocalStorageHelper.setItem('hasBookedCall', String(hasBookedCall));
    }
}

export type IBookACallStore = BookACallStore;

let bookStore: BookACallStore | undefined;

export const useBookACallStore = (): BookACallStore => {
    if (!bookStore) {
        bookStore = new BookACallStore();
        if (typeof window !== 'undefined') {
            window.Vino.bookACallStore = bookStore;
        }
    }

    return bookStore;
};
