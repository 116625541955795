/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-argument */
import { useEffect } from 'react';
import { useRouter } from 'next/router';

import { PosthogHelper } from '~/helpers/PosthogHelper';
import { SessionStorageHelper } from '~/helpers/StorageHelper';

import { UtmParams } from './useCaptureUtmParams';
import { useUpdateAccountInfo } from '../account/useUpdateAccountInfo';
import { useGetUserProfile } from '../queries/portfolios/useGetUserProfile';

const snakeToCamel = (str: string) =>
    str.toLowerCase().replace(/([_-][a-z])/g, (group) => group.toUpperCase().replace('-', '').replace('_', ''));

export const useSendUtmParams = () => {
    const router = useRouter();

    const { data: profile } = useGetUserProfile();
    const { mutateAsync: updateInvestmentPlan } = useUpdateAccountInfo();
    useEffect(() => {
        const utmValues = Object.values(UtmParams);
        const capturedUtmParams = utmValues
            .map((key) => {
                return [key, SessionStorageHelper.getItem<any>(key)];
            })
            .filter(Boolean);

        if (profile && capturedUtmParams.length > 0) {
            const updateUtm = async () => {
                try {
                    const properties = capturedUtmParams.reduce((acc, [key, param]) => {
                        if (param) {
                            PosthogHelper.registerProperty(key, param);
                            acc[snakeToCamel(key)] = param;
                            SessionStorageHelper.removeItem(key);
                        }
                        return acc;
                    }, {} as any);

                    if (properties) {
                        const utmParams = { ...properties };
                        await updateInvestmentPlan(utmParams);
                    }
                } catch {
                    console.error('Failed to send utm params');
                }
            };
            void updateUtm();
        }
    }, [router.pathname, profile]);
};
