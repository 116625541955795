import { getCurrencyCode } from '~/utils/localization';

/**
 * Format a number to currency. Uses localCurrency cookie for currency code or defaults to USD.
 * @param {number} x - number to format as currency. Defaults to 0.
 * @param {boolean} decimal - Whether to include decimal value in returned string. Defaults to true.
 *
 */
export const formatCurrency = (amount = 0, decimal = true, currencyCode = getCurrencyCode()): string => {
    const currencyFractionDigits = decimal
        ? new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: currencyCode
          }).resolvedOptions().maximumFractionDigits
        : 0;

    return amount.toLocaleString(currencyCode, {
        currency: currencyCode,
        style: 'currency',
        maximumFractionDigits: currencyFractionDigits,
        minimumFractionDigits: currencyFractionDigits
    });
};

/**
 * Format number into users local currency in cents.
 * Denomination * 100.
 * @param amount - amount
 * @example - $500.00
 */
export const formatCurrencyInCents = (amount: number, decimal = true, currencyCode = getCurrencyCode()): string => {
    return formatCurrency(amount / 100, decimal, currencyCode);
};

/**
 * Format the currency with a default placeholder number if 0.
 * @param amount - amount to format
 * @param placeholder - placeholder to use
 * @returns
 */
export const formatCurrencyWithPlaceholder = (amount: number, placeholder = 1000): string => {
    if (amount < placeholder) {
        const currencyStr = formatCurrencyInCents(amount > 0 ? amount + placeholder : placeholder);
        return currencyStr.replace(/(\d)/, '0');
    }
    return formatCurrencyInCents(amount);
};

/**
 * Format cent dollars as a rounded whole number.
 * @param amount - amount as cent dollars.
 * @example - 50000 returns as $500
 */
export const formatCurrencyRoundedCents = (amount: number): string => {
    const roundedCentDollars = Math.round(amount / 100);
    return formatCurrency(roundedCentDollars, false);
};
