import { ImageProps } from 'next/legacy/image';

import Fund from '~/assets/img/reserve-cask/svg/fund.svg';
import Receive from '~/assets/img/reserve-cask/svg/receive.svg';
import Sell from '~/assets/img/reserve-cask/svg/sell.svg';
import Watch from '~/assets/img/reserve-cask/svg/watch.svg';

import { HowWhiskeyvestWorksProps } from './HowWhiskeyvestWorksCard';

const makeValueCard = (title: string, imgSrc: ImageProps['src'], description: string, id: string) => {
    return { title, imgSrc, description, id };
};

export const howWhiskeyvestWorksCardConstants: HowWhiskeyvestWorksProps[] = [
    makeValueCard('Fund your account', Fund, 'American Whiskey starts at $1,750.', '1'),
    makeValueCard('Receive whiskey', Receive, 'We source, authenticate, insure and store your whiskey. ', '2'),
    makeValueCard('Track your growth', Watch, 'We recommend holding your whiskey for 4 to 8+ years', '3'),
    makeValueCard('Sell your whiskey', Sell, 'We will sell your cask when it reaches maturity.', '4')
];
