/**
 *
 * Replaces {number} in a url string with the values provided in order they are provided.
 *
 * example:
 * formatUrl('vinovest.co/v1/portfolio/{0}/settings', '8375kfds7dsgb')
 * => 'vinovest.co/v1/portfolio/8375kfds7dsgb/settings'
 *
 * formatUrl('vinovest.co/vi/portfolio/{0}/wines/{1}', '4k32l532fcs08790', '4225325')
 * => 'vinovest.co/vi/portfolio/{4k32l532fcs08790}/wines/{4225325}'
 */
export function formatUrl(url: string, ...obj: string[]): string {
    let urlCopy = url;
    // eslint-disable-next-line guard-for-in
    for (const x in obj) {
        urlCopy = urlCopy.replace(new RegExp(`{[${x}]}`), obj[x] && encodeURIComponent(obj[x]));
    }
    return urlCopy;
}
