/* eslint-disable no-nested-ternary */
import styled from 'styled-components';

import { AnalyticsEventNames } from '~/constants/AnalyticsEventMap';
import { PosthogHelper } from '~/helpers/PosthogHelper';
import { LocalStorageHelper } from '~/helpers/StorageHelper';
import { baseRoutes } from '~/routes/base';
import { Button } from '~/vinovest-design-system/components';

export interface ButtonProps {
    location: string;
    title?: string;
    height?: string;
    width?: string;
    destination?: string;
    typeOfItem?: string;
}

export const GreenButton = ({
    location,
    title = 'Start Investing',
    width,
    height,
    destination,
    typeOfItem
}: ButtonProps) => {
    const locationTracking =
        typeOfItem === 'lots'
            ? 'lots'
            : typeOfItem === 'american'
              ? 'american casks'
              : typeOfItem === 'ultra_rare_scotch'
                ? 'scotch'
                : location;
    const redirectUrl = destination;
    const handleClick = () => {
        if (location === 'whiskey_card' && redirectUrl) {
            LocalStorageHelper.setItem('whiskeyRedirectUrl', redirectUrl);
        }
        PosthogHelper.captureEvent(AnalyticsEventNames.SelectGetStarted, { location: locationTracking });
    };
    return (
        <ButtonGreen
            href={
                location === 'whiskey_card'
                    ? `${baseRoutes.signup.href}?callback_pathname=${destination}`
                    : baseRoutes.signup.href
            }
            variant={'green'}
            overwriteClassName={false}
            onClick={handleClick}
            location={location}
            $width={width || '232px'}
            $height={height || '74px'}
        >
            {title}
        </ButtonGreen>
    );
};

export const ButtonGreen = styled(Button)<{ $width: string; $height: string }>`
    align-items: center;
    display: flex;
    font-size: 16px;
    height: ${(props) => (props.$height ? props.$height : '74px')};
    justify-content: center;
    margin: 0 auto;
    max-width: ${(props) => (props.$width ? props.$width : '232px')};
    width: 100%;
`;
