import { FC } from 'react';

import { WhatToExpectListCard, WhatToExpectListProps } from './WhatToExpectCard';

interface WhatToExpectProps {
    cards: WhatToExpectListProps[];
}
export const WhatToExpectList: FC<WhatToExpectProps> = ({ cards }) => {
    return (
        <div className={'relative z-1 sm:p-[40px] lg:p-[80px 80px 0]'}>
            <div
                className={
                    'flex flex-col md:flex-row gap-[20px] around justify-center items-center content-center mx-[15px] md:mx-0 center'
                }
            >
                {cards.map((cardProps) => {
                    return <WhatToExpectListCard {...cardProps} key={cardProps.id} />;
                })}
            </div>
        </div>
    );
};
