import Image from 'next/legacy/image';
import styled from 'styled-components';

import WhiskeyFaq from '~/assets/img/reserve-cask/svg/whiskey-faq.svg';
import WhiskeyGuide from '~/assets/img/reserve-cask/whiskey-guide.jpg';
import { AnalyticsEventNames } from '~/constants/AnalyticsEventMap';
import { useTracking } from '~/hooks/tracking/useTracking';
import { whiskeyRoutes } from '~/routes/whiskey';
import { Button, Heading } from '~/vinovest-design-system/components';

export const WhiskeyEducationSupportContainer = () => {
    const { captureEvent } = useTracking();
    const handleGuideClick = () => {
        captureEvent(AnalyticsEventNames.WhiskeyGuide);
    };
    const handleWhiskeyFaqClick = () => {
        captureEvent(AnalyticsEventNames.WhiskeyFaq);
    };
    return (
        <HwiwContainer className={'h-full sm:bg-transparent max-w-[1170px] mx-auto'}>
            <div className={'flex justify-center flex-col'}>
                <Heading level={'h2'} classnames={'xs:!text-[32px] lg:!text-[45px] text-center !leading-loose'}>
                    Education &amp; Support
                </Heading>
            </div>
            <div className={'flex justify-center flex-col md:flex-row gap-4'}>
                <Box className={'border border-[#b5b5b5] radius-[8px]'}>
                    <ImageContainer>
                        <Image
                            src={WhiskeyGuide}
                            alt={'Whiskey Guide'}
                            layout={'fill'}
                            objectFit={'contain'}
                            className={'!w-[283px] !h-[160px]'}
                        />
                    </ImageContainer>
                    <Title>American Whiskey Guide</Title>
                    <Description>
                        Learn about whiskey and its market dynamics in our comprehensive American Whiskey Guide.
                    </Description>
                    <BoxCta>
                        <Button
                            onClick={handleGuideClick}
                            href={whiskeyRoutes.guide.href}
                            variant={'custom'}
                            classnames={`text-[20px] capitalize mt-0 mb-0 text-brown-600 !font-vinovestmedium flex flex-row items-center !p-0 justify-end`}
                        >
                            <BtnLabel>Explore now</BtnLabel>
                            <>
                                <svg
                                    width={'9'}
                                    height={'12'}
                                    viewBox={'0 0 10 12'}
                                    fill={'none'}
                                    xmlns={'http://www.w3.org/2000/svg'}
                                >
                                    <line x1={'0'} y1={'5'} x2={'9'} y2={'5'} stroke={'#A86D37'} strokeWidth={'1.5'} />
                                    <path d={'M4 1 L9 5 L4 9'} stroke={'#A86D37'} strokeWidth={'1.5'} />
                                </svg>
                            </>
                        </Button>
                    </BoxCta>
                </Box>
                <Box className={'border border-[#b5b5b5] radius-[8px]'}>
                    <ImageContainer>
                        <Image
                            src={WhiskeyFaq}
                            alt={'Whiskey Faq'}
                            layout={'fill'}
                            objectFit={'contain'}
                            className={'!w-[168px] !h-[126px] mx-auto'}
                        />
                    </ImageContainer>
                    <Title>Help Center &amp; FAQ</Title>
                    <Description>
                        Learn more about Vinovest and get answers to our investors' most commonly asked questions.
                    </Description>
                    <BoxCta>
                        <Button
                            onClick={handleWhiskeyFaqClick}
                            href={'/help-category/whiskeyinvesting'}
                            variant={'custom'}
                            classnames={`text-[20px] capitalize mt-0 mb-0 text-brown-600 !font-vinovestmedium flex flex-row items-center !p-0 !justify-end`}
                        >
                            <BtnLabel>Learn more</BtnLabel>
                            <>
                                <svg
                                    width={'9'}
                                    height={'12'}
                                    viewBox={'0 0 10 12'}
                                    fill={'none'}
                                    xmlns={'http://www.w3.org/2000/svg'}
                                >
                                    <line x1={'0'} y1={'5'} x2={'9'} y2={'5'} stroke={'#A86D37'} strokeWidth={'1.5'} />
                                    <path d={'M4 1 L9 5 L4 9'} stroke={'#A86D37'} strokeWidth={'1.5'} />
                                </svg>
                            </>
                        </Button>
                    </BoxCta>
                </Box>
            </div>
        </HwiwContainer>
    );
};

export const HwiwContainer = styled.div`
    padding: 20px 0;
    position: relative;
`;

export const Box = styled.div`
    background-color: #fff;
    border-radius: 8px;
    max-width: 343px;
    padding: 30px;
    position: relative;
    text-align: left;
`;

export const ImageContainer = styled.div`
    align-items: center;
    background-color: #f8f3ef;
    display: flex;
    height: 160px;
    justify-content: center;
    position: relative;
    width: 100%;
    z-index: 0;
    @media screen and (min-width: 1024px) {
        width: 283px;
    }
`;

export const Title = styled.h3`
    font-family: var(--font-roslindale);
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
    margin: 17px 0;
`;

export const Description = styled.p`
    margin: 17px 0;
    padding: 0;
`;

export const BoxCta = styled.div`
    padding: 40px 0 0;
`;

const BtnLabel = styled.span`
    font-size: 16px;
    display: inline-block;
    margin-right: 7px;
`;
