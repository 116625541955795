import { FC } from 'react';
import Image, { ImageProps } from 'next/legacy/image';
import styled from 'styled-components';

import { Heading, Placeholder, Text } from '~/vinovest-design-system/components';

export interface ValuePropCardProps {
    imgSrc?: ImageProps['src'];
    imgHeight?: ImageProps['height'];
    imgWidth?: ImageProps['width'];
    title?: string;
    description?: string;
    badgeTitle?: string;
    id?: string;
}
export const ValuePropCard: FC<ValuePropCardProps> = (props) => {
    const { title, description, badgeTitle } = props;
    const imageProps: any = typeof props.imgSrc === 'string' ? { src: props.imgSrc } : { ...props.imgSrc };
    return (
        <li
            className={
                'flex flex-col md:relative md:pl-[184px] md:max-w-[740px] md:w-[100%] md:mx-auto md:text-left p-3 md:pb-[60px]'
            }
        >
            <ValuePropImage className={'md:!absolute md:left-0 md:top-0 md:!mb-[0]'}>
                <Image
                    {...imageProps}
                    layout={'fill'}
                    objectFit={'contain'}
                    className={'!w-[144px] !h-[144px]'}
                    alt={'decorative image'}
                    aria-hidden
                />
            </ValuePropImage>
            <Placeholder renderCondition={!!title}>
                <Heading level={'h3'} classnames={'mb-4 !text-[24px] md:!text-[32px]'}>
                    {title}
                </Heading>
            </Placeholder>
            <Placeholder renderCondition={!!description}>
                <div className={'w-full relative'}>
                    {badgeTitle != null && (
                        <span
                            className={
                                'bg-black leading-none px-2 py-1 rounded-r rounded-tl text-10 text-white uppercase absolute -top-1/2 -translate-x-1/2 left-1/2'
                            }
                        >
                            {badgeTitle}
                        </span>
                    )}
                    <Text
                        classnames={
                            'text-16 leading-[26px] inline-flex align-top flex-col flex flex-grow max-w-[330px] md:max-w-[100%] w-full'
                        }
                    >
                        {description}
                    </Text>
                </div>
            </Placeholder>
        </li>
    );
};

const ValuePropImage = styled.div`
    height: 144px;
    margin: 0 auto 20px;
    position: relative;
    text-align: center;
    width: 144px;
`;
