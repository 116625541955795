import { ImageProps } from 'next/legacy/image';

import American from '~/assets/img/reserve-cask/what-to-expect/American-Bourbon.png';
import Rye from '~/assets/img/reserve-cask/what-to-expect/High-Rye-Bourbon.png';

import { WhatToExpectListProps } from './WhatToExpectCard';

export interface WhatToExpectTableRowProps {
    year: string;
    caskValue: string;
    storageCost: string;
    netValue: string;
    totalReturn: string;
    annualizedRetrun: string;
    isOptimal?: boolean;
    id: string;
}

const whatToExpectCard = (
    title?: string,
    imgSrc?: ImageProps['src'],
    holdTime?: string,
    exitPrice?: string,
    returnValue?: string,
    id?: string
) => {
    return { title, imgSrc, holdTime, exitPrice, returnValue, id };
};

const whatToExpectTableRow = (
    year: string,
    caskValue: string,
    storageCost: string,
    netValue: string,
    totalReturn: string,
    annualizedRetrun: string,
    id: string,
    isOptimal?: boolean
) => {
    return { year, caskValue, storageCost, netValue, totalReturn, annualizedRetrun, id, isOptimal };
};

export const WhatToExpectTableConstants: WhatToExpectTableRowProps[] = [
    whatToExpectTableRow('Year 1', '$1,600', '$51', '$1,549', '3%', '3%', '1'),
    whatToExpectTableRow('Year 2', '$1,900', '$55', '$1,794', '20%', '9%', '2'),
    whatToExpectTableRow('Year 3', '$2,800', '$63', '$2,631', '75%', '21%', '3'),
    whatToExpectTableRow('Year 4', '$3,500', '$70', '$3,261', '117%', '23%', '4'),
    whatToExpectTableRow('Year 5', '$4,200', '$78', '$4,883', '159%', '21%', '5'),
    whatToExpectTableRow('Year 6', '$4,600', '$85', '$4,198', '180%', '18%', '6', true)
];

export const WhatToExpectCardConstants: WhatToExpectListProps[] = [
    whatToExpectCard('High-Rye Whiskey (Batch 1)', Rye, '7 months', '$1,850', '30.7%', '1'),
    whatToExpectCard('High-Rye Whiskey (Batch 2)', Rye, '7 months', '$1,850', '30.7%', '2'),
    whatToExpectCard('American Bourbon', American, '1 year', '$1,850', '23.3%', '3')
];
