import axios from 'axios';

import Endpoints from '~/constants/endpoints';
/* eslint-disable */
// @ts-ignore
import type { useGetUserPortfolioQuery } from '~/hooks/queries/portfolios/useGetUserPortfoliosQuery';
/* eslint-enable */
import { GetCollectionByIdResponse } from '~/models/GetCollectionByIdResponse';
import { UserProfile } from '~/models/UserProfile';
import { formatUrl } from '~/utils/formatUrl';

/** Fetcher function for useGetUserPortfoliosQuery
 * @see {@link useGetUserPortfolioQuery}
 */
export const fetchUserPortfolios = async (jwt: string, userId: string) => {
    return axios
        .get<GetCollectionByIdResponse>(formatUrl(Endpoints.GET_ALL_PORTFOLIOS, userId), {
            headers: {
                Authorization: `Bearer ${jwt}`,
                'Accept-Language': 'en, zh'
            }
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.error(error);
        });
};

/**
 * Update a users profile (or partial profile) information
 * @param jwt - access token from okta
 * @param userId - userId from okta token
 * @param userInformationToUpdate - Partial {@link UserProfile user profile}  information to update
 */
export const updateUserProfile = (jwt: string, userId: string, userInformationToUpdate: Partial<UserProfile>) => {
    return axios
        .put(formatUrl(Endpoints.USER_PROFILE_INFORMATION, userId), userInformationToUpdate, {
            headers: {
                Authorization: `Bearer ${jwt}`,
                'Accept-Language': 'en, zh'
            }
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.error(error);
        });
};

/**
 * Update a users profile (or partial profile) information
 * @param jwt - access token from okta
 * @param userId - userId from okta token
 * @param userInformationToUpdate - Partial {@link UserProfile user profile}  information to update
 */
export const getUserProfile = (jwt: string, userId: string) => {
    return axios
        .get<UserProfile>(formatUrl(Endpoints.USER_PROFILE_INFORMATION, userId), {
            headers: {
                Authorization: `Bearer ${jwt}`,
                'Accept-Language': 'en, zh'
            }
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.error(error);
        });
};
