import Image from 'next/legacy/image';

import Check from '~/assets/img/reserve-cask/svg/icon-check.svg';
import { GreenButton } from '~/components/views/TrackRecord/GreenButton';
import { Button } from '~/vinovest-design-system/components';

import { ReserveListProps } from './ReserveList';

export interface WhiskeyCardProps extends ReserveListProps {
    imgSrc: { src: string; width: number | string; height: number | string };
    handleReserveClick: () => void;
    blackVariant: boolean;
    callout?: string;
    showLoggedIn: boolean;
    type: string;
}

export const WhiskeyCard: React.FC<WhiskeyCardProps> = ({
    volume,
    buttonLabel,
    title,
    name,
    price,
    handleReserveClick,
    imgSrc,
    url,
    unitTitle,
    callout,
    showLoggedIn,
    type,
    pointsList
}) => {
    return (
        <div
            className={`${
                callout ? 'bg-platinum-800' : 'bg-brown-200'
            } rounded-[8px] w-[100%] md:max-w-[50%] mx-[auto] cursor-pointer`}
            onClick={handleReserveClick}
            onKeyDown={handleReserveClick}
            role={'button'}
            tabIndex={0}
        >
            <div className={`relative z-0 p-[20px]`}>
                {callout && (
                    <span
                        className={
                            'font-mono absolute bg-yellow-600 rounded-[23px] top-[-15px] font-[500] right-[20px] text-turtleGreen-800 uppercase text-14 inline-flex justify-center items-center h-[28px] w-[125px]'
                        }
                    >
                        {callout}
                    </span>
                )}
                <div
                    className={`${
                        callout ? 'text-brown-300' : 'text-black'
                    } text-[24px] text-left mt-[5px] font-[500] leading-[1.2]`}
                >
                    {title}
                </div>
                <div className={`${callout ? 'text-brown-300' : 'text-gray-500'} text-[16px] text-left`}>{name}</div>
                <div className={`flex my-[20px]`}>
                    <div className={`w-[40%] flex flex-col`}>
                        <span className={`${callout ? 'text-brown-300' : 'text-neutrals-600'} text-[14px] text-left`}>
                            {unitTitle}
                        </span>
                        <span
                            className={`${
                                callout ? 'text-brown-300' : 'text-black'
                            } font-roslindale text-[26px] text-left`}
                        >
                            {price}
                        </span>
                        <span
                            className={`${
                                callout ? 'text-brown-300' : 'text-neutrals-600'
                            } text-[14px] text-left mt-[10px]`}
                        >
                            {volume}
                        </span>
                    </div>
                    <div className={`w-[60%] flex flex-col justify-center`}>
                        <div
                            className={`!w-[190px] !h-[150px] md:!w-[270px] md:!h-[213px] relative md:mt-[-40px] mx-auto`}
                        >
                            <Image
                                src={imgSrc.src}
                                alt={`${name} Image`}
                                layout={'fill'}
                                priority
                                objectFit={'contain'}
                                className={'!w-[190px] !h-[150px] md:!w-[270px] md:!h-[213px]'}
                            />
                        </div>
                    </div>
                </div>
                {showLoggedIn && (
                    <div className={'md:mt-[-80px] md:text-left'}>
                        <Button
                            variant={'green'}
                            classnames={`!text-16 !font-vinovestmono w-[302px] md:w-[200px] h-[56px] mb-[10px] !leading-[14px] inline-flex justify-center items-center`}
                            onClick={handleReserveClick}
                            href={url}
                        >
                            {buttonLabel}
                        </Button>
                    </div>
                )}
                {!showLoggedIn && (
                    <div className={'mt-8'}>
                        <GreenButton
                            title={'Get Started'}
                            location={'whiskey_card'}
                            width={'100%'}
                            destination={url}
                            typeOfItem={type}
                        />
                    </div>
                )}
                <ul className={`mt-[20px] text-left ${callout ? 'text-brown-300' : 'text-black '} `}>
                    {pointsList.map((item) => {
                        return (
                            <li
                                key={item.key.toLowerCase().replace(' ', '')}
                                className={`mb-[15px] pl-[30px] relative`}
                            >
                                <i
                                    className={
                                        'text-brown-300 bg-green-600 absolute left-[0] top-[3px] rounded-[30px] !w-[18px] !h-[18px] text-[12px] flex items-center leading-[0] justify-center'
                                    }
                                >
                                    <Image
                                        src={Check}
                                        alt={'Icon Check'}
                                        layout={'fill'}
                                        objectFit={'contain'}
                                        className={'!w-[18px] !h-[18px]'}
                                    />
                                </i>{' '}
                                {item.description}
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};
