import { useMutation } from 'react-query';

import { mutations } from '~/constants/mutationKeys';
import { VinovestNetworkingHelper } from '~/helpers/VinovestNetworkingHelper';
import { PutUserInformationPayload } from '~/networking';

import { useAuthentication } from '../useAuthentication';

export const useUpdateAccountInfo = () => {
    const { isAuthenticated } = useAuthentication(false);
    return useMutation([mutations.UpdateUserAccount], async (updater: PutUserInformationPayload) => {
        if (!isAuthenticated) {
            return false;
        }
        const { managed } = VinovestNetworkingHelper.getClient();
        const result = await managed.putUserInformation(updater);
        return result.ok;
    });
};
