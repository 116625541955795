import { useContext, useEffect } from 'react';
import { useQueryClient } from 'react-query';

import { queries } from '~/constants/queryKeys';
import { DepositContext } from '~/context/Deposit/DepositContext';
import { SentryHelper } from '~/helpers/Sentry/SentryHelper';
import { usePutProfile } from '~/hooks/managed/usePutProfile';
import { useManagedPortfolioQuery } from '~/hooks/queries/portfolios/managed/useManagedPortfolioQuery';

export const useEnsureManagedPortfolio = () => {
    const { data: managedPortfolio, isFetched } = useManagedPortfolioQuery();
    const { mutateAsync } = usePutProfile();

    const { depositAmount } = useContext(DepositContext);
    const queryClient = useQueryClient();
    const makePortfolio = async () => {
        await mutateAsync(
            {
                investingStyle: 'moderate',
                anticipatedCashAccess: 'not sure',
                consideredStartingInvestment: depositAmount
            },
            {
                onSuccess: async () => {
                    queryClient.invalidateQueries([queries.getManagedPortfolio]);
                },
                onError: async (error) => SentryHelper.captureException(error)
            }
        );
    };

    useEffect(() => {
        if (managedPortfolio?.code === 401 && isFetched) {
            makePortfolio();
        }
    }, [managedPortfolio, depositAmount, isFetched]);
};
