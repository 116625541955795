import { useMutation } from 'react-query';

import { queries } from '~/constants/queryKeys';
import { VinovestNetworkingHelper } from '~/helpers/VinovestNetworkingHelper';
import { useAuthentication } from '~/hooks/useAuthentication';
import { PortfolioDefinition } from '~/networking';

/** Query for fetching users portfolios  */
export const useCreateWhiskeyPortfolioMutation = () => {
    const { jwt: token, userId } = useAuthentication();

    return useMutation<PortfolioDefinition | undefined>([queries.getUserProfile, userId], async () => {
        if (userId && token) {
            const { managed } = VinovestNetworkingHelper.getClient({ userId });
            const userPortfolios = await managed?.createWhiskeyvestPortfolio({ userId });
            if (userPortfolios.ok) {
                return userPortfolios.data;
            }
        }
        return {} as PortfolioDefinition;
    });
};
