import { FC } from 'react';
import { ImageProps } from 'next/legacy/image';
import styled from 'styled-components';

import { Heading, Placeholder, Text } from '~/vinovest-design-system/components';

export interface HowWhiskeyvestWorksProps {
    imgSrc?: ImageProps['src'];
    title?: string;
    description?: string;
    badgeTitle?: string;
    id?: string;
}
export const HowWhiskeyvestWorksCard: FC<HowWhiskeyvestWorksProps> = (props) => {
    const { title, description, badgeTitle, id } = props;
    return (
        <HiwCard className={'flex flex-col p-3'}>
            <HiwStep className={`font-mono`}>{id}</HiwStep>
            <Placeholder renderCondition={!!title}>
                <Heading level={'h6'} classnames={'!normal-case !text-[20px]'}>
                    {title}
                </Heading>
            </Placeholder>

            <Placeholder renderCondition={!!description}>
                <div>
                    {badgeTitle != null && (
                        <span
                            className={
                                'bg-black leading-none px-2 py-1 rounded-r rounded-tl text-10 text-white uppercase absolute -top-1/2 -translate-x-1/2 left-1/2'
                            }
                        >
                            {badgeTitle}
                        </span>
                    )}
                    <Text className={'text-13 mt-4'}>{description}</Text>
                </div>
            </Placeholder>
        </HiwCard>
    );
};

export const HiwCard = styled.li`
    background-color: #fff;
    border: 1px solid #b5b5b5;
    border-radius: 8px;
    padding: 32px 22px 54px;
    position: relative;
    text-align: left;
`;

export const HiwImage = styled.div`
    bottom: 25px;
    height: 100px;
    left: 50%;
    position: absolute;
    text-align: center;
    transform: translateX(-50%);
    width: 100%;
`;

export const HiwStep = styled(Text)`
    align-items: center;
    background-color: #3c400c;
    border-radius: 30px;
    color: #fae8d1;
    display: flex;
    font-family: var(--font-roslindale);
    font-size: 20px;
    height: 36px;
    justify-content: center;
    left: -17px;
    margin-bottom: 10px;
    position: absolute;
    top: -17px;
    width: 36px;
`;

export const HiwStepIcon = styled.i`
    background-color: #333;
    height: 1px;
    left: 60px;
    position: absolute;
    top: 9px;
    width: calc((100% - 40px) + 67px);
    &:after {
        border-color: transparent transparent transparent #000;
        border-style: dashed dashed dashed solid;
        border-width: 4px 9px;
        content: '';
        position: absolute;
        right: 0;
        top: -3px;
        transform: translateX(9px);
    }
`;
