import { isDesktop } from 'react-device-detect';

export const ReserveTitle = () => {
    return (
        <>
            {isDesktop && (
                <h1 className={'hero-title !brown-300 xs:w-[300px] sm:w-full text-[36px] md:text-[64px]'}>
                    Invest in Whiskey
                </h1>
            )}
        </>
    );
};
